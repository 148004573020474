













































































































































































.legal {
  font-size: 13px;
  line-height: 1.35;

  .checkbox-wrapper {
    display: flex;
    margin-bottom: 16px;
  }

  .checkbox-input {
    margin-top: 2px;
  }

  .checkbox-label {
    margin-left: 10px;
    display: inline-block;
  }
}
