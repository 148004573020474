











.content-wrapper {
  position: fixed;
  bottom: 0;
  right: 0;
  height: 60px;
  padding: 5px 25px;
  background-color: #fff;
  border-top: solid 1px #e6e6e6;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 10;
}
