














































































































































































.content-wrapper {
  display: flex;
  width: 100vw;
  padding-top: 40px;
  justify-content: center;
}

.content-box {
  width: 600px;
  box-shadow: 0 0 25px #cac6c6;
  border-radius: 5px;
  padding: 25px;
}

h2 {
  text-align: center;
  margin-bottom: 24px;
}
