
















































































































































































































































































































































































































$header-height: 60px;
$sidebar-width: 300px;

#layout-wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

header {
  width: 100%;
  position: fixed;
  height: $header-height;
  top: 0;
  left: 0;
  background-color: #fff;
  border-bottom: solid 1px #e6e6e6;
  z-index: 100;
  padding: 10px 25px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.app-body {
  margin-top: $header-height;
  display: flex;
  flex-grow: 1;
  flex-direction: row;
  overflow-x: hidden;
}

aside {
  position: fixed;
  height: 100%;
  width: $sidebar-width;
  background-color: #42707e;
}

main {
  flex: 1;
  margin-left: $sidebar-width;
  margin-bottom: 80px;
}

.el-menu {
  height: 100%;
}

.el-dropdown-link {
  cursor: pointer;
}

.logo img {
  height: 32px;
  width: auto;
}

.region-logo-container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px 10px;
  border-radius: 4px;
  transition: background-color 0.3s ease;
}
