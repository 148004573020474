





















.login-wrapper {
  display: flex;
  height: 100vh;
  width: 100vw;
  align-items: center;
  justify-content: center;
}

.logo {
  display: flex;
  justify-content: center;
}
