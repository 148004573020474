























.page-content {
  padding: 0 25px;
}

.page-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;

  h1 {
    margin-top: 6px;
    margin-bottom: 6px;
  }
}

.page-actions-wrapper {
  display: flex;
  align-items: center;

  .el-select {
    margin-right: 10px;
  }
}
