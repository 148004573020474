@import "normalize";
@import "element-variables";
@import "animations";

@import url('https://rsms.me/inter/inter.css');
html { font-family: 'Inter', sans-serif; }
@supports (font-variation-settings: normal) {
  html { font-family: 'Inter var', sans-serif; }
}

body {
  min-height: 100vh;
}

body,
html {
  scroll-behavior: smooth;
}

.el-submenu [class^="fa"],
.el-submenu [class*="fa"],
.el-menu-item [class^="fa"],
.el-menu-item [class*="fa"]
 {
  margin-right: 5px;
  width: 24px;
  text-align: center;
  font-size: 18px;
  vertical-align: middle;
}

.el-form--label-top {
  .el-form-item__label {
    line-height: 16px;
  }

  .el-form-item__content {
    line-height: inherit;
  }

  .el-form-item {
    margin-bottom: $fa-spacer * 5;
  }

  .el-select {
    width: 400px;
  }

  & > .el-card {
    margin-bottom: $fa-spacer * 5;
  }
}

.el-card {
  .el-form-item:last-child {
    margin-bottom: 0px;
  }
}

.el-tree-node__label {
  font-size: 16px;
}

.el-notification {
  width: 450px;
  cursor: pointer;
}

.el-date-range-picker.has-sidebar {
  .el-picker-panel__sidebar {
    width: 130px;
  }

  .el-picker-panel__body {
    margin-left: 130px;
  }
}

.buttons-wrapper {
  display: flex;
  justify-content: space-between;
}

.button-right {
  margin-left: auto;
}

.checkbox-item {
  .el-form-item__content {
    line-height: 28px;
  }
}

.main-menu-wrapper {
  overflow-y: scroll;
}

.main-menu {
  height: auto !important;
  border-right: none !important;
  padding-bottom: 60px;

  i {
    color: lighten(#42707E, 40%);
  }
}


// TipTap Editor Styles
.editor__content:focus,
.editor__content .ProseMirror:focus {
  outline: 0px solid transparent;
}

.ProseMirror {
  * {
    caret-color: currentColor;
  }

  ul,
  ol {
    padding-left: 1rem;
  }

  li > p,
  li > ol,
  li > ul {
    margin: 0;
  }

  a {
    color: inherit;
  }

  img {
    max-width: 100%;
  }

  p.is-empty:first-child::before {
    content: attr(data-empty-text);
    float: left;
    color: #aaa;
    pointer-events: none;
    height: 0;
    font-style: italic;
  }
}

.el-pagination.is-background {
  padding: 0;
  margin-top: 24px;

  .btn-prev {
    margin-left: 0;
  }
}

.shepherd-element {
  background: #444 !important;
  max-width: 500px !important;

  .shepherd-arrow::before {
    background: #444;
  }
}

.shepherd-text {
  color: white !important;

  a {
    color: #67C23A;
  }
}

.shepherd-footer,
.shepherd-text {
  padding-left: 25px !important;
  padding-right: 25px !important;
}

.shepherd-header {
  padding-top: 25px !important;
}

.shepherd-footer {
  padding-bottom: 25px !important;
}

.shepherd-left-button {
  margin-right: auto !important;
}

body flowise-chatbot::part(button) {
  display: none;
}

body.show-chatbot flowise-chatbot::part(button) {
  display: flex;
}