
















































.wrapper {
  display: flex;
  height: 100vh;
  width: 100vw;
  align-items: center;
  justify-content: center;

  h2 {
    margin-right: 24px;
  }
}
